import { Link } from '@reach/router'
import { parseDateToString, parseToDate } from '@root/src/utils/date-parser/date-parser'
import { appPaths, getPathUrl } from 'utils/path'
import { getDetailPageUrl, getSearchQueryParam, isDoctorUrl } from 'utils/url'
import { Body, BodySecondary, Button, Col, Divisor, Grid, H2, Row, Separator } from 'atomic'
import { Badge } from 'atomic/legacy/atm.badge'
import { graphql, StaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import LargeSeparatorRow from '../../../../atomic/atm.large-separator-row/large-separator-row.component'
import { ExternalHtmlWrapper } from '../atm.wrapper/wrapper.component'
import { SEO } from '../legacy/mol.seo/seo.component'
import { ShareIconsNewsCell } from '../mol.share-icons-cell/share-icons-cell.component'
import TitleWithBreadcrumbRow from '../org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { PreviousContentTitle, PreviousContentWrapper } from './news-articles-detail-page.style'

export interface NewsDetailPageProps {
  data: ArticleNewsPageData
  slug: string
  currentPageUrl: string
  isDoctor: boolean
  canonicalUrl: string
  staticInfo: StaticInfo
}

interface PreviousContentCell {
  title: string
  description: string
  publishedDate: string
  slug: string
}

export interface ArticleNewsPageData {
  title: string
  description: string
  publishedDate: Date
  htmlContent: string
  tags?: string[]
  previousContentData: PreviousContentCell[]
  seo: {
    title: string
    imageUrl: string
    imageAlt: string
    description: string
    article: {
      published_time: Date
      modified_time: Date
      expiration_time?: Date
      autor: string[]
      section: string
      tag: string[]
    }
  }
}

interface StaticInfo {
  col2Title: string
  col2ButtonTitle: string
  col2ButtonTo: string
  col2ReadMoreButton: string
  section: 'Noticias' | 'Artigos'
}

export const NewsArticlesDetailPage: React.FunctionComponent<NewsDetailPageProps> = props => {
  const data = props.data
  const pageData = data
  const staticInfo = props.staticInfo
  const localPath = getPathUrl(getPath(staticInfo.section).path, props.isDoctor)
  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.slug}`] = data.title

  return (
    <>
      <StaticQuery
        query={graphql`
          {
            seoGeral: cosmicjsInformacoesEstaticas(slug: { eq: "seo-geral" }) {
              metadata {
                nomeDaMarca
              }
            }
          }
        `}
        render={staticData => (
          <SEO
            socialMedia={{
              canonicalUrl: props.canonicalUrl,
              title: data.seo.title,
              image: data.seo.imageUrl,
              imageAlt: data.seo.imageAlt,
              description: data.seo.description,
              article: {
                author: [staticData.seoGeral.metadata.nomeDaMarca],
                modified_time: data.seo.article.modified_time,
                published_time: data.seo.article.published_time,
                section: staticInfo.section,
                // TODO: NEWS_DETAIL - ajustar para mostrar tags
                tag: [] // data.metadata.tags
              }
            }}
          />
        )}
      />

      <Grid>
        <TitleWithBreadcrumbRow
          title={pageData.title}
          addtionalDictionary={breadcrumbAdditionalDictionary}
        />

        <Row mb>
          <Col xs={12} md={7}>
            <Row>
              <Col xs={12}>
                <Body>{pageData.description}</Body>
                <Separator />
                <Divisor />
                <Separator />
              </Col>
              <Col xs={12} md={6}>
                <BodySecondary>{`Publicado em ${parseDateToString(
                  pageData.publishedDate
                )}`}</BodySecondary>
                <Separator />
              </Col>
              <Col xs={12} mdOffset={1} md={5} lgOffset={2} lg={4}>
                <ShareIconsNewsCell
                  url={props.currentPageUrl}
                  text={pageData.description}
                  title={pageData.title}
                />
                <Separator />
              </Col>
            </Row>
            <LargeSeparatorRow />
            <ExternalHtmlWrapper dangerouslySetInnerHTML={{ __html: pageData.htmlContent }} />
            <Separator />
            <Divisor />
            <Separator />

            {pageData.tags && (
              <Row mb mt>
                <Col xs={12} md={7}>
                  <Row>
                    {pageData.tags.map(tag => (
                      <>
                        <Col>
                          <Link
                            to={`${getPathUrl(
                              appPaths.search.path,
                              isDoctorUrl(props.currentPageUrl)
                            )}${getSearchQueryParam(tag)}`}
                          >
                            <Badge text={tag} kind="secondary" />
                          </Link>
                          <Separator />
                        </Col>
                      </>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={false} md={1} />
          <Col xs={12} md={4}>
            <Row>
              <Col xs={12}>
                <H2 cell>{staticInfo.col2Title}</H2>
                <Separator />
                {pageData.previousContentData.map((content, index) => (
                  <PreviousContentWrapper
                    href={content.slug /*getDetailPageUrl(localPath, content.slug)*/}
                  >
                    <PreviousContentTitle>{content.title}</PreviousContentTitle>
                    <Body>
                      {content.description}{' '}
                      <Button
                        id={`button-read-more-articles-${index}`}
                        kind="link"
                        // to={'noticias/' + content.slug} //getDetailPageUrl(localPath, content.slug)
                      >
                        {staticInfo.col2ReadMoreButton}
                      </Button>
                    </Body>
                    <Separator />
                    <BodySecondary>{`Publicado em ${parseDateToString(
                      parseToDate(content.publishedDate)
                    )}`}</BodySecondary>
                    <Separator />
                    {index !== pageData.previousContentData.length - 1 && <Divisor />}
                  </PreviousContentWrapper>
                ))}
              </Col>
              <Col xs={12} md={8}>
                <Button
                  id="button-title-news-articles"
                  kind="secondary"
                  expanded
                  to={staticInfo.col2ButtonTo}
                >
                  {staticInfo.col2ButtonTitle}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export const getPath = (section: 'Noticias' | 'Artigos') => {
  switch (section) {
    case 'Artigos':
      return appPaths.scientificArticles
    case 'Noticias':
      return appPaths.news
    default:
      return null
  }
}
